import React from 'react'
import PropTypes from 'prop-types'

const KwaliteitLabel = ({title, body, image}) => (
    <div className="slider">
        <div className="image">
            <img src={image} alt="Kwaliteitslabel a"/>
        </div>
        <div className="text">
            <h4 className="title" style={{lineHeight: 1, marginBottom: 10, textAlign:'left', color: '#2A7D2E'}}>
                {title}
            </h4>
            <p style={{color: '#0B3D2C', textAlign: 'justify'}}>
                {body}
            </p>
        </div>
    </div>
);
export default KwaliteitLabel;

KwaliteitLabel.propTypes = {
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
}